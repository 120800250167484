import React from 'react'
import { graphql } from 'gatsby'

import SEO from 'components/seo'
import ContactContent from 'components/Contact'

const ContactTemplate = ({ data }) => {
  const { markdownRemark } = data
  return (
    <>
      <SEO title="Contact" />
      <ContactContent data={markdownRemark} />
    </>
  )
}

export default ContactTemplate

export const query = graphql`
  query Contact($locale: String!, $title: String!) {
    markdownRemark(frontmatter: { title: { eq: $title } }, fields: { locale: { eq: $locale } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      html
    }
  }
`
