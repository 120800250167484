import React from 'react'

import { ContactWrapper, Content, ImageContainer } from './styles'

const ContactContent = ({ data }) => {
  const {
    frontmatter: {
      title,
      image: {
        childImageSharp: { fluid }
      }
    },
    html
  } = data
  return (
    <ContactWrapper>
      <Content>
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Content>
      <ImageContainer bgImage={fluid.src} opacity={0.5} />
    </ContactWrapper>
  )
}

export default ContactContent
